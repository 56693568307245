<template>
  <div>
    <BaseTable
      ref="purchase-invoices-reports-table"
      :resource="$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICE_REPORTS"
      :resourceStore="'purchaseInvoicesReports'"
      :columns="columns"
      :filters="filters"
      :totals-configuration="totalsConfiguration"
      :loading="loading"
      @filter-button-click="filtersVisible = !filtersVisible"
      @row-clicked="
        $router.push({
          name: 'viewPurchaseInvoice',
          params: { id: $event.id },
        })
      "
      @on-clear-active-filters="handleClearListFilters"
      @data-loaded="$emit('data-loaded', $event)"
    >
      <template #cell(job)="{ value }">
        <span>{{ value ? value.code : "" }}</span>
      </template>
      <template #cell(provider)="{ value }">
        <span>{{ value.name }}</span>
      </template>
      <template #cell(invoice_date)="{ value, item }">
        <div>{{ value | formatDate }}</div>
        <div v-show="item.expiration_date" class="font-small text-light">
          vence {{ item.expiration_date | formatDate }}
        </div>
      </template>
      <template #cell(expiration_date)="{ value, item }">
        <span
          v-show="
            item.status.name === 'Pendiente de pago' ||
            item.status.name === 'Pago parcial'
          "
          :class="
            (isDueDateExpired(value) ? 'text-danger' : 'text-primary') +
            ' text-uppercase'
          "
        >
          {{ getDueDateDaysText(value) }}
        </span>
      </template>
      <template #cell(status)="data">
        <StatusBadge 
          :status="data.item.status.name" 
          :text="data.item.status.name === 'Pago parcial' ? 
            `Pago parcial ${data.item.percent !== 0 && data.item.percent !== 100 ? `${data.item.percent} % ` : '' }` 
          : null" 
        />
        <StatusBadge
          v-if="data.item.send_payment_document && data.item.send_payment_document === 1"
          class="mt-1" 
          :status="'Envio doc. pago'" 
          :text="'Envio doc. pago'"
          :variant="'light-success'"
        />
      </template>
      <template #cell(total_gross)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_invoice)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_pending_payment)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_liquid)="{ value }">
        <span>{{ value | numberToLocalString }} €</span>
      </template>
      <template #cell(total_retention)="data">
        <div>{{ data.item.total_retention | numberToLocalString }} €</div>
        <div
          v-show="data.item.retention_expiration"
          class="font-small text-light"
        >
          vence {{ data.item.retention_expiration | formatDate }}
        </div>
      </template>
    </BaseTable>
    <PurchaseInvoicesReportsListFilters
      v-model="filtersVisible"
      ref="purchase-invoices-reports-list-filters"
      @filters-submit="$store.commit('purchaseInvoiceReports/setFilters', $event)"
    />
  </div>
</template>

<script>
import DateTimeService from "@/shared/services/date-time-service";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import PurchaseInvoicesReportsListFilters from "@/components/purchase-invoices-reports/filters/PurchaseInvoicesReportsListFilters.vue";
import ApiRestService from "@/api/base-api";
import { mapGetters } from 'vuex'

export default {
  name: "PurchaseInvoicesReportsTable",
  components: { 
    PurchaseInvoicesReportsListFilters, 
    StatusBadge, 
    BaseTable 
  },
  data() {
    return {
      loading: false,
      filtersVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      filters: 'purchaseInvoiceReports/getFilters',
    }),
    totalsConfiguration () {
      return [
        { colspan: 7 },
        { label: 'TOTAL PENDIENTE', key: 'total_pending_payment', unit: '€' },
        { label: "TOTAL BRUTO", key: "total_gross" },
        { label: "TOTAL RETENCIÓN", key: "total_retention" },
        { label: "TOTAL LÍQUIDO", key: "total_liquid" },
        { label: "TOTAL", key: "total_invoice" }
      ]
    },
    columns() {
      const cols = [
        {
          label: "JOB",
          key: "job",
          sortable: true,
          thStyle: { "min-width": "100px" },
        },
        {
          label: "Nº factura proveedor",
          key: "provider_code",
          sortable: true
        },
        {
          label: "Numero",
          key: "number",
          sortable: true,
        },
        {
          label: "PROVEEDOR",
          key: "provider",
          sortable: true,
          thStyle: { "min-width": "220px" },
        },
        {
          label: "FECHA",
          key: "invoice_date",
          sortable: true,
        },
        {
          label: "VENCIMIENTO",
          key: "expiration_date",
          sortable: true,
        },
        {
          label: "ESTADO FACTURACIÓN",
          key: "status",
          sortable: true,
        },
        {
          label: "T. PENDIENTE",
          key: "total_pending_payment",
          sortable: true,
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          label: "T. BRUTO",
          key: "total_gross",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
          thStyle: { "min-width": "100px" },
        },
        {
          label: "RETENCIÓN",
          key: "total_retention",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "LÍQUIDO A PERCIBIR",
          key: "total_liquid",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
        },
        {
          label: "T. FACTURA",
          key: "total_invoice",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
          thStyle: { "min-width": "120px" },
        },
      ];
      
      return cols
    },
  },
  methods: {
    getDueDateDaysText(dueDate) {
      if (!dueDate) {
        return "";
      }

      const days = DateTimeService.getDifferenceInDays(
        new Date(),
        new Date(dueDate)
      );
      return this.isDueDateExpired(dueDate)
        ? `Vencida ${days} dias`
        : `Vence en ${days} dias`;
    },
    isDueDateExpired(dueDate) {
      if (!dueDate) {
        return false;
      }

      return new Date() > new Date(dueDate);
    },
    loadData() {
      this.$refs["purchase-invoices-reports-table"].loadData();
    },
    handleClearListFilters() {
      this.$store.commit('purchaseInvoiceReports/setFilters', {})
      this.$refs["purchase-invoices-reports-list-filters"].clearFilters();
    },
    async handlePrintIconClick(purchaseInvoice) {
      const { id, name } = purchaseInvoice.documentation[0];

      try {
        await ApiRestService.downloadArchive(id, name);
      } catch {}
    },
  },
};
</script>
